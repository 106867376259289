import React from 'react';

import Layout from '../components/layout';

const notFoundPage = () => {
    return (
        <Layout>
            <h1>Page not found.</h1>
        </Layout>
    );
};

export default notFoundPage;
